import { composeActionEvent, composeScreenEvent } from './utils'

const commonParams = {
    screenClass: 'mis productos',
    tipoOperativa: 'alquiler',
    tipologia: 'movilidad',
    subtipologia: 'transaccional',
    seccion1: 'mis productos',
    seccion2: 'alquiler',
    productosContratados: 'coche',
}

// ------- LANDING ---------- //
export const showLandingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler landing',
    seccion3: 'landing',
})

export const clickSearchLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler landing',
    seccion3: 'landing',
    accionEvento: 'clic_buscar',
    etiquetaEvento: 'alquiler',
})

// ------- PROCESS ---------- //
export const showResultsView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados',
    seccion3: 'resultados',
})

export const showMoreInfoPopUpView = (seller) =>
    composeScreenEvent({
        ...commonParams,
        screenName: 'alquiler info ' + seller,
        seccion3: 'info ' + seller,
    })

export const clickC2Clink = (view = 'resultados') =>
    composeActionEvent({
        ...commonParams,
        screenName: 'alquiler ' + view,
        seccion3: view,
        accionEvento: 'c2c',
        etiquetaEvento: 'alquiler',
    })

export const applyFiltersLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler resultados',
    seccion3: 'resultados',
    accionEvento: 'aplicar_filtro',
    etiquetaEvento: 'alquiler',
})

export const showFilteredResultsView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados filtro',
    seccion3: 'resultados filtro',
})

export const showNoResultsViews = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler sin resultado',
    seccion3: 'sin resultados',
})

export const cleanFiltersLink = (results) =>
    composeActionEvent({
        ...commonParams,
        screenName: results
            ? 'alquiler resultados filtro'
            : 'alquiler sin resultado',
        seccion3: results ? 'resultados filtro' : 'sin resultado',
        accionEvento: 'borrar_filtro',
        etiquetaEvento: 'alquiler',
    })

export const showExtrasView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler extras',
    seccion3: 'extras',
})

export const addExtraLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler extras',
    seccion3: 'resultados',
    accionEvento: 'anadir_extra',
    etiquetaEvento: 'alquiler',
})

export const showLoginView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler iniciar sesion',
    seccion3: 'iniciar sesion',
})

export const showOTPLoginView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler verificar identidad',
    seccion3: 'verificar identidad',
})

export const showUserDataView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler datos conductor',
    seccion3: 'datos conductor',
})

export const showConfirmBookingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion ok',
    seccion3: 'confirmacion ok',
})

export const showQRConfirmBookingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion qr',
    seccion3: 'confirmacion qr',
})

export const submitRentalCarBooking = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion ok',
    seccion3: 'confirmacion ok',
    accionEvento: 'alquiler_fin',
    etiquetaEvento: 'alquiler fin',
})

export const modifyButtonRentalCarBooking = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion ok',
    accionEvento: 'modif_reserva_alquiler',
    etiquetaEvento: 'modificar reserva alquiler',
})

export const cancelModifyButtonRentalCarBooking = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler necesitas modificar reserva',
    seccion3: 'confirmacion ok',
    accionEvento: 'cancelar_reserva_alquiler',
    etiquetaEvento: 'cancelar reserva alquiler',
})

export const keepModifyButtonRentalCarBooking = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler necesitas modificar reserva',
    seccion3: 'confirmacion ok',
    accionEvento: 'mantener_reserva_alquiler',
    etiquetaEvento: 'mantener reserva alquiler',
})

export const showModifyRentalCarBooking = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler necesitas modificar reserva',
    seccion3: 'modificar reserva',
})

export const ageSelectorCarBooking = (age) => composeActionEvent({
    ...commonParams,
    event: 'clic_edad',
    screenName: 'alquiler landing',
    accionEvento: 'clic_edad',
    etiquetaEvento: 'alquiler',
    edadDesplegable: age,
    seccion3: 'landing',
})

export const showC2CModal = (pageName) => composeScreenEvent({
    ...commonParams,
    screenName: `alquiler ${pageName} c2c inicio`,
    seccion3: `${pageName} c2c inicio`,
})

export const showC2cPhoneSent = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados c2c gracias',
    seccion3: 'resultados c2c inicio',

})

export const eventC2cPhoneSent = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'alquiler resultados',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'resultados',
    IDEvento: 'necesitas info gracias',
})

export const showC2cInactivity = composeScreenEvent({
    ...commonParams,
    screenName: `resultados c2c inactividad inicio`,
    seccion3: `resultados c2c inactividad inicio`,

})

export const eventC2cInactivity = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: `resultados c2c inactividad inicio`,
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler inicio',
    seccion3: 'resultados',
    IDEvento: 'inactividad inicio',
})

export const showC2cInactivityPhoneSent = composeScreenEvent({
    ...commonParams,
    screenName: `resultados c2c inactividad gracias`,
    seccion3: `resultados c2c inactividad gracias`,

})

export const eventC2cInactivityPhoneSent = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: `resultados c2c inactividad gracias`,
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'c2c inactividad gracias',
    IDEvento: 'inactividad gracias',
})
